<template>
  <class-wrap
    :class-data="classData"
    :unlock-data="unlockData"
    :content-info="contentInfo"
    :active-class="activeClass"
  >
    <template slot="ico">&#xe855;</template>
    音频<span class="i" v-if="content.duration">&nbsp;|&nbsp;{{content.duration}}</span>
  </class-wrap>
</template>
<script>

import { Component, Vue, Prop, Mixins, Watch } from 'vue-property-decorator'
import ClassWrap from './class-wrap.vue'
import ClassMixins from '@/modules/course/components/class-list/class-mixins'

  @Component({
    components: {
      ClassWrap
    }
  })
export default class ClassAudioItem extends Mixins(ClassMixins) {
}
</script>
<style lang="scss" scoped rel="stylesheet/scss">
</style>
