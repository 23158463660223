import {Vue} from 'vue/types/vue';
import {getH5Host} from '@/utils/utils';

// 暂时不支持 非视频的直播
export default function (info: any, vue: Vue, type?: string): boolean {
  let flag = true
  let host = getH5Host()
  let liveType = info.live_type
  if (liveType === 1 || liveType === 6 || liveType === 8) {
    let url: string
    if (type === 'course') {
      url = `${host}/?#/courseStudio/${info.course_class_id}/${info.course_id}?classContentId=${info.content_id}`
    } else if(liveType === 8) { // 小程序直播类型跳转到对应h5的详情页
      url = `${host}/?#/single/${info.content_id}`
    }else {
      url = `${host}/?#/nStudio/${info.content_id}`
    }
    vue.$showH5qrCode(url, '更多精彩内容请访问移动端店铺')
    flag = false
  }
  return flag
}
