import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import * as utils from '@/utils/utils'
import {ClassInfo, CourseInfo} from '@/modules/course/models'
@Component({
  filters: {
    zeroFormat(v: number) {
      return v < 10 ? '0' + v : '' + v
    }
  }
})
export default class CharpterMixins extends Vue {
  @Prop() data: any
  @Prop() contentInfo!: CourseInfo
  @Prop({default: ''}) activeClass!: string
  @Prop() unlockData: any

  showCtn: boolean = true

  get processingPrecent() {
    let studyProgress = this.data.study_progress || 0
    let precent = utils.accMul(studyProgress, 100)
    return precent
  }

  classTypeComponent(v: string) {
    let map: any = {
      article: 'item-article',
      video: 'item-video',
      audio: 'item-audio',
      exam: 'item-exam',
      live: 'item-live'
    }
    return map[v] || ''
  }

  toggle() {
    this.showCtn = !this.showCtn
  }
}
