















import { Component, Vue, Prop, Mixins, Watch } from 'vue-property-decorator'
import Charpter from './components/charpter-wrap.vue'
import ClassListIndexMixins from '@/modules/course/components/class-list/index-mixins'

  @Component({
    components: {
      Charpter
    }
  })
export default class ClassList extends Mixins(ClassListIndexMixins) {
}
