<template>
  <div class="charpter-wrap">
    <div class="hd" @click="toggle" :class="{none: !showCtn || data.class_content.length < 1, active: processingPrecent === 100}">
      <div class="title">
        {{data._index | zeroFormat}}.{{data.title}}
        <ds-icon :code="showCtn ? '&#xe6ab;' : '&#xe69b;'" class="arr-ico"></ds-icon>
      </div>
      <div class="label-group" v-show="showCtn">
        <span v-if="processingPrecent === 100"><ds-icon class="h5tech" code="&#xe699;"></ds-icon>已学完</span>
        <span v-else-if="contentInfo.is_subscribe">已学{{processingPrecent}}%</span>
      </div>
    </div>
    <div class="bd" v-show="showCtn">
      <template v-for="(v, i) in data.class_content">
        <component
          v-if="classTypeComponent(v.content_type)"
          :is='classTypeComponent(v.content_type)'
          :class-data="v"
          :active-class="activeClass"
          :unlock-data="unlockData"
          :content-info="contentInfo"
          :key="i"
        ></component>
      </template>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Mixins, Watch } from 'vue-property-decorator'
import ItemArticle from './article'
import ItemAudio from './audio'
import ItemVideo from './video'
import ItemLive from './live'
import ItemExam from './exam'
import CharpterMixins from '@/modules/course/components/class-list/charpter-mixins'

  @Component({
    components: {
      ItemArticle,
      ItemAudio,
      ItemVideo,
      ItemLive,
      ItemExam
    }
  })
export default class CharpterWrap extends Mixins(CharpterMixins) {
}
</script>
<style lang="scss" scoped rel="stylesheet/scss">
  .charpter-wrap{
    &:last-child{
      border-bottom:1px solid #E6E6E6;
    }
  }
  .hd{
    padding:20px 0;
    position:relative;
    border-top:1px solid #E6E6E6;
    user-select:none;
    .title{
      font-size:14px;
      font-weight:bold;
      line-height:1em;
      position:relative;
      text-overflow:ellipsis;
      overflow:hidden;
      white-space:nowrap;
      padding-right:16px;
    }
    .label-group{
      margin-top:10px;
      color:#999;
      font-size:12px;
      margin-left:20px;
      .h5tech{
        display:inline-block;
        transform:scale(.7);
        margin-right:5px;
      }
    }
    .arr-ico {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) scale(.6);
      right:0;
      color:#CCCCCC;
    }
  }
  .bd{
    padding-left:20px;
    margin-top:-10px;
    padding-bottom:10px;
  }
</style>
