<template>
  <div class="class-wrap" :class="{active: active}" @click="toDetail">
    <div class="title">
      <div class="type-ico"></div>
      <span class="label" v-if="isShowTest">试学</span>{{classData.title}}
    </div>
    <div class="desc">
      <slot></slot><span class="i" v-if="shopShowData.view && !contentInfo.is_subscribe">&nbsp;|&nbsp;{{classData.view_count | numberFormat}}次学习</span><span class="leared" v-show="learedObj.show">&nbsp;|&nbsp;<ds-icon class="ico" code="&#xe699;" v-show="learedObj.ico"></ds-icon>{{learedObj.text}}</span><ds-icon class="lock-ico" code="&#xe6b2;" v-show="locked"></ds-icon>
    </div>
    <div class="ext-info">
      <slot name="extInfo"></slot>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Mixins, Watch } from 'vue-property-decorator'
import ClassWrapMixins from '@/modules/course/components/class-list/class-wrap-mixins'

  @Component({
  })
export default class ClassWrap extends Mixins(ClassWrapMixins) {
  get active() {
    let id = '' + this.classData.id
    let cId = ''
    if(this.classData.content_type == "exam"){
      cId = '' + this.$route.query.cId 
    } else {
      cId = '' + this.$route.params.cId
    }
    
    if (this.activeClass) {
      cId = '' + this.activeClass
    }
    return id === cId
  }
}
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import '@/style/theme.scss';
  .class-wrap{
    position:relative;
    padding:14px 20px 14px 0;
    cursor:pointer;
    &:after{
      top:0;
      bottom:auto;
    }
    &:before{
      content:'';
      display:block;
      position:absolute;
      width:1px;
      top:20px;
      height:100%;
      left:-14px;
      background:#ccc;
    }
    &:first-child{
      &:after{
        width:auto;
        left:-20px;
        right:0;
      }
    }
    &:last-child{
      &:before{
        display:none;
      }
    }
    &.active{
      .title{
        .type-ico{
          border:1px solid $color-theme;
          background:$color-theme;
        }
      }
    }
    .title{
      font-size:12px;
      line-height:1em;
      text-overflow:ellipsis;
      overflow:hidden;
      white-space:nowrap;
      color:#333;
      .type-ico{
        width:10px;
        height:10px;
        line-height:10px;
        border-radius:50%;
        background:#fff;
        border:1px solid #ccc;
        text-align:center;
        position:absolute;
        left:-19px;
        top: 15px;
        z-index: 1;
        .h5tech{
          margin:0;
          font-size:20px;
        }
      }
      .label{
        color:$color-theme;
        margin-right:5px;
      }
    }
    .desc{
      margin-top:10px;
      font-size:13px;
      line-height:1em;
      color:#999;
      .leared{
        font-size:11px;
        margin-top:4px;
        .ico{
          margin-right:5px;
          display:inline-block;
          vertical-align:middle;
          transform:scale(.7);
        }
      }
      .lock-ico{
        margin:0 5px;
      }
    }
    .ext-info,
    .desc,
    .title{
      transition:all .3s;
    }
    &.active,
    &:hover{
      .ext-info,
      .desc,
      .title{
        color:$color-theme !important
      }
    }
  }
</style>
