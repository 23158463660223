import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import { ClassInfo, CourseInfo } from '@/modules/course/models'
import enterCheck from '@/modules/studio/pages/detail/enterCheck';

@Component({
  filters: {
    zeroFormat(v: number) {
      return v < 10 ? '0' + v : '' + v
    }
  }
})
export default class ClassWrapMixins extends Vue {
  @Prop() classData!: ClassInfo
  @Prop() contentInfo!: CourseInfo
  @Prop({default: ''}) activeClass!: string
  @Prop() unlockData: any

  get shopShowData() {
    return {
      view: true
    }
  }
  get isShowTest() {
    return !this.contentInfo.is_subscribe && this.classData.is_free
  }
  get locked() {
    let sub = this.contentInfo.is_subscribe
    let lockMode = this.contentInfo._lockMode
    let isPreview = this.$route.query.token
    let unlocked = this.classData._unlocked
    let flag = false
    // 直播 不支持解锁
    if (lockMode && sub) {
      flag = !unlocked
    }
    return !isPreview && flag
  }
  get learedObj() {
    // 普通课程：
    //   已学完的课时：显示“√已学完”
    //   已浏览过但未学完的课时：显示“未学完”
    //   未浏览的课时：不显示相关信息
    //
    // 解锁课程：
    //   已学完的课时：显示“√已学完”
    //   已浏览过但未学完的课时：显示“未学完”
    //   已解锁过但未浏览的课时：显示“未学完”
    //   未解锁的课时：不显示相关信息
    let show = this.classData.is_learn && this.contentInfo.is_subscribe
    let ico = this.classData._precent === 100
    let text = this.classData._precent === 100 ? '已学完' : '未学完'
    if (this.contentInfo._lockMode) {
      show = this.classData.is_learn || this.classData._unlocked
    }
    return {
      show,
      ico,
      text
    }
  }

  toDetail() {
    console.log(this.classData, 'this.classData');
    
    let canEnter = this.classData.is_free || this.contentInfo.is_subscribe
    let type = this.classData.content_type
    let courseId = this.contentInfo.content_id
    let cId = this.classData.id
    console.log(canEnter, type, courseId, cId, '11111111');
    console.log(this.classData.is_free,'this.classData.is_free');
    console.log(this.contentInfo.is_subscribe,'this.contentInfo.is_subscribe')

    if (this.locked) {
      this.$message('抱歉，该课时还未解锁，请先完成前置课时')
      return
    }

    if (canEnter) {
      if (['article', 'audio', 'video', 'exam'].indexOf(type) !== -1) {
        let route = {
          name: 'courseClass',
          params: {
            courseId,
            cId
          }
        }
        if (type === 'exam') {
          let route = {
            name: 'examDetail',
            params: {
              id: (this.classData.content && this.classData.content.id) || this.classData.content_id
            },
            query: {
              type: 'course',
              contentId: courseId,
              cId: this.classData.id
            }
          }
          this.$router.push(route)
          // this.$showH5qrCode(route1, '更多精彩内容请访问移动端店铺')
        } else {
          this.$router.push(route)
        }
      } else if (type === 'live') {
        if (enterCheck(this.classData.content, this, 'course')) {
          this.$router.push({
            name: 'courseStudio',
            params: {
              id: cId,
              courseId
            },
            query: {
              classContentId: this.classData.content.content_id
            }
          })
        }
      }
    } else {
      this.$message('加入课程后才能看')
    }
  }
}
