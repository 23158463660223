import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import { ClassInfo, CourseInfo } from '@/modules/course/models'

@Component({
  filters: {
    zeroFormat(v: number) {
      return v < 10 ? '0' + v : '' + v
    }
  }
})
export default class ClassMixins extends Vue {
  @Prop() classData!: ClassInfo
  @Prop() contentInfo!: CourseInfo
  @Prop({default: ''}) activeClass!: string
  @Prop() unlockData: any

  get content() {
    return this.classData.content
  }
}
