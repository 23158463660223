import { Component, Vue, Prop, Mixins, Watch } from 'vue-property-decorator'
import {getChapterList, getUnlockList, updateUnlock} from '@/modules/course/api'
import * as utils from '@/utils/utils'
import {CharpterClass, ClassInfo, CourseInfo} from '@/modules/course/models'
import {checkLogin, getSign} from '@/utils/user';

@Component({
  components: {
  }
})
export default class ClassListIndexMixins extends Vue {
  @Prop() courseDetail!: CourseInfo
  @Prop() classDetail!: ClassInfo
  @Prop({default: ''}) activeClass!: string
  @Prop({ default: () => [] }) fromCharpterList!: Array<CharpterClass>
  @Prop({ default: null }) fromUnlockData!: any

  charpterList: Array<CharpterClass> = []

  loading: boolean = false
  unLockData: any = {}
  open: boolean = true
  sort: number = 1 // 1正 2倒

  @Watch('open')
  onOpenChanged(val: boolean, oldVal: boolean) {
    console.log(this.$refs.charpter, 'this.$refs.charpter');
    
    (this.$refs.charpter as any).forEach((e: any) => {
      e.showCtn = val
    })
  }

  @Watch('fromCharpterList', { immediate: true })
  onFromCharpterListChanged(val: Array<CharpterClass>, oldVal: Array<CharpterClass>) {
    this.charpterList = val
  }
  @Watch('fromUnlockData', { immediate: true })
  onFromUnlockDataChanged(val: any, oldVal: any) {
    if (val) {
      this.unLockData = val
    }
  }

  mounted() {
    this.getData()

    if (this.courseDetail._lockMode && this.courseDetail.is_subscribe && !this.fromUnlockData) {
      this.getUnlockData()
    }
  }

  get formatList() {
    // 这里要用 新数组 新对象 不然 子组件 不会响应数据
    let list: Array<any> = []
    let targetIds = this.unLockData.target_ids || []
    targetIds = targetIds.map((e: any) => parseInt(e.split(':')[1]))
    let format = this.charpterList.map((e: CharpterClass, i: number) => {
      e._index = i + 1
      // 处理课时数据
      e.class_content = [].concat(e.class_content.map((v: any, i: any) => {
        let cStudyProgress = v.study_progress || 0
        let cPrecent = utils.accMul(cStudyProgress, 100)
        let nextClass = e.class_content[i + 1]
        let _line = false
        if (nextClass) {
          let studyProgress = nextClass.study_progress || 0
          let precent = utils.accMul(studyProgress, 100)
          _line = cPrecent === 100 && precent === 100
        }
        v['_line'] = _line
        v['_precent'] = cPrecent
        v['_unlocked'] = targetIds.indexOf(v.id) !== -1
        return Object.assign({}, v)
      }))
      return e
    })
    list = list.concat(format)
    return this.sort === 1 ? list : list.reverse()
  }

  get classCount() {
    let sum = 0
    this.charpterList.forEach(e => {
      sum += e.class_content.length
    })
    return sum
  }

  get classList() {
    let list: any = []
    this.charpterList.forEach(e => {
      list = list.concat(e.class_content)
    })
    return list
  }
  get classIdxObj() {
    let curIdx: number = -1
    let sameIdxArr: Array<any> = []
    let cId = this.classDetail.id
    let classType = this.classDetail.content_type
    this.classList.forEach((e: any, i: any) => {
      if (e.id === cId) {
        curIdx = i
      }
      if (e.content_type === classType) {
        sameIdxArr.push(i)
      }
    })
    let arrIdx = sameIdxArr.indexOf(curIdx)
    return {
      sameIdxArr,
      preIdx: sameIdxArr[arrIdx - 1],
      nextIdx: sameIdxArr[arrIdx + 1],
      curIdx
    }
  }
  get preClass() {
    return this.classList[this.classIdxObj.preIdx]
  }
  get nextClass() {
    return this.classList[this.classIdxObj.nextIdx]
  }
  get firstClass() {
    return this.classList[this.classIdxObj.sameIdxArr[0]]
  }

  unlockNext() {
    let lockMode = this.courseDetail._lockMode
    let completedList = this.unLockData.completed_target_ids || []
    let unlockedList = this.unLockData.target_ids || []
    let classCompleted = completedList.map((e: any) => parseInt(e.split(':')[1])).indexOf(this.classDetail.id) !== -1
    let classUnlocked = unlockedList.map((e: any) => parseInt(e.split(':')[1])).indexOf(this.classDetail.id) !== -1

    let logined = checkLogin()
    if (!logined) return Promise.resolve()
    let userId = getSign().id
    // 解锁模式 & 当前课时未完成 & 当前课时已解锁
    if (lockMode && !classCompleted && classUnlocked) {
      // 解锁
      return updateUnlock({
        userId,
        contentId: this.courseDetail.content_id,
        chapterId: this.classDetail.chapter_id,
        classId: this.classDetail.id
      }).then((e: any) => {
        this.$emit('updateUnlockData', e)
      })
    } else {
      return Promise.resolve()
    }
  }

  getData() {
    this.loading = true
    return getChapterList({
      id: this.courseDetail.content_id,
      count: 999
    }).then(e => {
      this.loading = false
      let charpterList = e.data
      this.charpterList = charpterList
      this.$nextTick(() => {
        this.open = this.courseDetail.dir_style !== 'collapse'
      })
    }).catch(e => {
      this.loading = false
    })
  }

  changeSort() {
    this.sort = this.sort === 1 ? 2 : 1
  }

  changeOpen() {
    this.open = !this.open
  }

  getUnlockData() {
    let logined = checkLogin()
    if (!logined) return
    let userId = getSign().id
    let {content_id: contentId} = this.courseDetail
    return getUnlockList({userId, contentId}).then(e => {
      this.unLockData = e
    })
  }
}
